@import url('https://fonts.cdnfonts.com/css/helvetica-neue-9');

@font-face {
    font-family: "Helvetica Neue";
    src: url("../fonts/HelveticaNeue-Regular.ttf");
}

.navbarDiv{
    width: 90%;
    float: left;
    padding-left: 5%;
    padding-right: 5%;
}
nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: transparent;
    padding-top: 1.426%;
    width: 100%;
}

.navbar1_div {
    margin-right: 5%;
}

.navTopic{
    width: 100%;
}
.hamburgerCrossBtn{
    display: none;
}

.signUp_btn {
    height: 49px;
    width: 86.66666412353516px;
    left: 713.3330078125px;
    top: 0px;
    border-radius: 9.772332191467285px;
    background-color: #FDBC2A;
    color: #000000;
    font-family: 'Helvetica 45 Light', sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: center;
    border: none;
    cursor: pointer;

}
.signUp_btn:hover{
    background-color: #ECECF2;
    border: 2px solid #FDBC2A;
    color: #FDBC2A;
}

#navbar {
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 50%; */
}

#navbar li {
    list-style: none;
    padding: 0 20px;
    position: relative;
}

#navbar li a {
    text-decoration: none;
    color: #fff;
    transition: 0.3s ease-in-out;
}

#navbar li a:hover {
    color: #fdbc2a;
}


#navbar li a:hover::after {
    content: "";
    width: 30%;
    height: 2px;
    /* background: #ffffff; */
    /* color: #FDDE69; */
    /* border: 1px solid #FDDE69; */
    cursor: pointer;
    position: absolute;
    bottom: -4px;
    left: 20px;
}

#mobile {
    display: none;
}

#mobile i {
    color: #fff;
}

.li_developer_docs a {
    font-family: 'Helvetica 45 Light', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 17.5902px;
    line-height: 21px;
    color: #FFFFFF;
}

@media screen and (max-width: 768px) {

    #navbar {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: fixed;
        top: -18px;
        right: -310px;
        width: 300px;
        height: 100vh;
        background: #4361cb;
        box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
        padding: 40px 0 0 10px;
        transition: 0.3s ease-in-out;
        z-index: 100;
    }

    #navbar.active {
        right: 0px;
    }

    #navbar li {
        margin-bottom: 25px;
    }

    #mobile {
        display: block;
    }

    #mobile i {
        font-size: 24px;
        cursor: pointer;
    }

}

.divsection{
    display: flex;
    flex-direction: column;
}

.headingStyle{
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 38px;
    color: #03004D;
    /* margin-bottom: -18px; */
}

.inputBox{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 5px 0;
}
.inputBox div{
    display: flex;
    flex-direction: column;
}
.confirmationCheck{
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-top: 10px;
}
.subheadingStyle{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #7F85A2;
    margin-bottom: 30px;
}
.labelHeading{
    font-style: normal;
font-weight: 700;
font-size: 14px;
/* line-height: 18px; */
/* identical to box height */


color: #1C1D21;

}
.buttonClass{
    background: #FDDE69;
    border-radius: 12px;
    width: 100%;
    height: 40px;
    margin-top: 20px;
    border: 1px solid #FDDE69;
    cursor: pointer;

}
.buttonClassText{
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    text-transform: capitalize;
    line-height: 20px;
    /* identical to box height, or 143% */

    text-align: center;

    /* Color/3/100% */

    color: #1E1F20;

}
.flexstyle{
    margin-bottom: -10px;
}
.siginStyle{
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height, or 150% */


    color: #8181A5;

}
.iconBorderColor{
    background: #FFFFFF;
    border: 1px solid #ECECF2;
    border-radius: 8px;
    padding: 10px;
}

.socialIcons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}

.desktopMode{
    display: block;
    width: 50%;
    padding-left: 10px;
    background: linear-gradient(198.52deg, #03004D -30.41%, #3563EB 109.49%);
}

.desktopMode img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.mobilemode{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px;
    padding-right: 40px;
    /* display: none; */
    width: 60%;
}
.parentClass{
    width: 65vw;
    left: 20%;
    top: 20%;
    position: absolute;
    border-top-left-radius: 10px;
    display: flex;
    flex-direction: row;
    margin: auto;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background: white;
    justify-content: space-between;
}

.thankyouParentClass{
    width: 55vw;
    height: 75vh;
    left: 20%;
    top: 20%;
    position: absolute;
    border-top-left-radius: 10px;
    display: flex;
    flex-direction: row;
    margin: auto;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background: white;
    justify-content: space-between;
}
.logostyle{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
}

.logostyle img{
    width: 46px;
    height: 46px;
}
.mobilelogo{
    display: none;
}
.error{
    color: red;
    font-size: 12px;
}
.mobilelogo img{
    width: 46px;
    height: 46px;
}

@media screen and (max-width: 600px) {
    .desktopMode{
        display: none;
    }
    .mobilemode{
        /* display: block; */
        width: 100%;
    }
    .parentClass{
        width: 100vw;
        left: 0%;
        top: 30;
        position: absolute;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }
    .thankyouParentClass{
        width: 100vw;
        left: 0%;
        top: 30;
        position: absolute;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px; 
    }
    .logostyle{
        display: none;
    }
    .mobilelogo{
        
        display: flex;
        flex-direction: column;
        gap: 10px;
        left: 28%;
        top: 10%;
        align-items: center;
        position: absolute;
    
    }
    .headingStyle{
        /* font-family: 'Circular Std'; */
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 38px;
        /* identical to box height */
    
        /* text-align: center; */
    
        color: #ffffff;
    }
    .navbarDiv{
        width: 90%;
        float: left;
        padding-left: 5%;
        padding-right: 5%;
        /* background-color: #1c3155; */
    }

    .hamburgerCrossBtn{
        display: block;
    }

}


.thankyouCss{
    align-items: center;
    display: flex;
    margin: auto;
    text-align: center;
    margin-top: 25%;
    width: 65%;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 19px;
}

